import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const biologicalInformationAnalysis = [
    {
        path: "microorganisms",
        name: "microorganisms",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/microorganisms.vue"),
        
    },
    {
        path: "PLS-DA",
        name: "PLS-DA",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/PLS-DA"),
       
    },
    {
        path: "BetaTree",
        name: "BetaTree",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/BetaTree"),
        meta: {
            title: "微生物β多样性进化树",

        },
    },
    {
        path: "BetaTreeHeatmap",
        name: "BetaTreeHeatmap",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/BetaTreeHeatmap"),
        meta: {
            title: "微生物β多样性聚类热图",

        },
    },
    {
        path: "TreeBar",
        name: "TreeBar",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/TreeBar"),
        meta: {
            title: "进化树柱状图",

        },
    },
    {
        path: "StandardizedRandomRateModel",
        name: "StandardizedRandomRateModel",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/StandardizedRandomRateModel"),
        meta: {
            title: "标准化随机率模型（NST）",
        },

    },
    {
        path: "AlphaDiversityDisplay",
        name: "AlphaDiversityDisplay",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/AlphaDiversityDisplay"),
        meta: {
            title: "α多样性展示",

        },

    },
    {
        path: "CorrelationWireHeatMap",
        name: "CorrelationWireHeatMap",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/CorrelationWireHeatMap"),
        meta: {
            title: "相关性连线热图",

        },

    },
    {
        path: "RelativeAbundanceCalculation",
        name: "RelativeAbundanceCalculation",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/RelativeAbundanceCalculation"),
        meta: {
            title: "相对丰度计算",

        },

    },
    {
        path: "MantelTestCorrelation",
        name: "MantelTestCorrelation",
        component: () => import("@/views/AdvancedAnalysis/microorganisms/MantelTestCorrelation"),
        meta: {
            title: "Mantel test相关性检验",

        },

    },
    {
        path: "circleTreemap",
        name: "circleTreemap",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/circleTreemap"),
        meta: {
            title: '环形进化树',

        }
    },
    {
        path: "annotationTreemap",
        name: "annotationTreemap",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/annotationTreemap"),
    },
    {
        path: "Sloan",
        name: "Sloan",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/Sloan"),
    },
    {
        path: "co_occurrenceNetwork",
        name: "co_occurrenceNetwork",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/co_occurrenceNetwork"),
    },
    {
        path: "MicrobialNetWork",
        name: "MicrobialNetWork",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/MicrobialNetWork"),
    },
    {
        path: "BioFlowerVenn",
        name: "BioFlowerVenn",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/BioFlowerVenn"),
    },
    {
        path: "BioPetalVenn",
        name: "BioPetalVenn",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/BioPetalVenn"),
    },
    {
        path: "OTUDrawFlatDetail",
        name: "OTUDrawFlatDetail",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/OTUDrawFlatDetail"),
    },
    {
        path: "MatrixDataDetail",
        name: "MatrixDataDetail",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/MatrixDataDetail"),
    },
    {
        path: "OTUAbundance",
        name: "OTUAbundance",
        component: () => import("@/views/biologicalInformationAnalysis/microorganisms/OTUAbundance"),
    },
    
    
]
const WithTypes = biologicalInformationAnalysis.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "微生物生信",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "微生物生信", // 替换成你想要的类型值
        },
    };
});

export default WithTypes