import { render, staticRenderFns } from "./Copy.vue?vue&type=template&id=0a80db6c&scoped=true"
import script from "./Copy.vue?vue&type=script&lang=js"
export * from "./Copy.vue?vue&type=script&lang=js"
import style0 from "./Copy.vue?vue&type=style&index=0&id=0a80db6c&prod&lang=scss&scoped=true"
import style1 from "./Copy.vue?vue&type=style&index=1&id=0a80db6c&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a80db6c",
  null
  
)

export default component.exports