<template>
  <div class="ca" :style="myStyle" :id="id" :ref="chart">
    <div
      class="img"
      v-if="isShow == false" 
      style="
        height: 99%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img
        ref="img"
        v-lazyload="chartImg"
        :src="URL"
        alt=""
        style="max-width: 100%; max-height: 100%"
      />
    </div>
    <!-- <div :id="id" :ref="chart" style="width: 100%; height: 100%" v-else></div> -->
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  props: {
    id: {
      type: String,
      default: "chart",
    },
    chart: {
      type: String,
      default: "chart",
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    imgurl: {
      type: String,
      default: null,
    },
    inputWidth: {
      type: Number,
      default: null,
    },
    inputHeight: {
      type: Number,
      default: null,
    },
    myChart: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartImg: null,
      URL: require("../../../assets/img/default_userPhoto/lazy.gif"),
      myStyle: {
        width: "65%",
        height: "95%",
      },
    };
  },
  watch: {
    imgurl: {
      handler() {
        this.getChartData();
      },
      deep: true,
    },
    inputWidth: {
      handler() {
        this.getWHeight();
      },
    },
    inputHeight: {
      handler() {
        this.getWHeight();
      },
    },
  },

  async mounted() {
    bus.$on("info", (data) => {
      console.log(data);
      this.getChartData(data[0]);
    });
  },
  methods: {
    getWHeight() {
      console.log(this.$refs.chart);
      //  this.$refs.chart.innerHTML = ""
      if (this.inputWidth && this.inputHeight) {
        this.myStyle.width = this.inputWidth + "px";
        this.myStyle.height = this.inputHeight + "px";
      } else if (this.inputWidth) {
        this.myStyle.width = this.inputWidth + "px";
      } else if (this.inputHeight) {
        this.myStyle.height = this.inputHeight + "px";
      }
      this.$nextTick(() => {
        // this.$parent.myEcharts();
        // this.$parent.onResize();
        
        if (this.isShow) {
          console.log(this.myChart);
          this.myChart.resize()
        }
        
      });
    },
    getChartData(data) {
      if (this.imgurl !== null) {
        if (!this.imgurl.includes("<svg")) {
          this.chartImg = window.g.baseUrl2 + this.imgurl;
          this.$nextTick(() => {
            // 获取到对应的 img 元素引用
            const imgElement = this.$refs.img;

            // 修改 img 元素的 src 属性
            if (imgElement) {
              imgElement.src = this.chartImg;
            }
          });
        }
      } else {
        this.chartImg = data.cover;
        // console.log(location_data[0].cover);
        if (this.chartImg.includes("/img/")) {
          this.chartImg = this.chartImg.replace("/img/", "");
        } else {
          this.chartImg = window.g.baseUrl2 + this.chartImg;
        }
        // 通过 $nextTick 确保 DOM 更新后再修改图片的 src
        this.$nextTick(() => {
          // 获取到对应的 img 元素引用
          const imgElement = this.$refs.img;

          // 修改 img 元素的 src 属性
          if (imgElement) {
            imgElement.src = this.chartImg;
          }
        });
      }
    },
  },
};
</script>
 
<style lang="scss" scoped>
.ca {
  overflow: hidden;
}
</style>