<template>
  <!-- 复制数据 -->
  <div class="copyAll">
    <el-tabs v-show="isTable" class="copy" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="图形数据" name="first">
        <div class="tips">
           
        </div>
        <div class="incre">
          <span class="el-dropdown-link">
            <i class="el-icon-plus" @click="incre(0)"></i>
          </span>
          <span class="el-dropdown-link">
            <i class="el-icon-minus" @click="desc(0)"></i>
          </span>
        </div>
        <div class="all">
          <div class="resizeMe" id="testDiv">
            <div
              _ngcontent-kyu-c381=""
              class="line"
              ref="line"
              @mousedown="doDown"
            >
              <img
                _ngcontent-kyu-c381=""
                src="../assets/tubiao1.png"
                style="width: 5px; height: 18px"
              />
            </div>
            <div class="innerTab">
              <hot-table
                :settings="hotSettings"
                :data="data1"
                ref="hotTable"
              ></hot-table>
            </div>
          </div>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="clear()"
          style="position: absolute; bottom: 20px; right: 20px"
        >
          清空
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <el-button
      type="success"
      @click="uploadSampleFile"
      style="position: absolute; bottom: 20px"
      @mouseenter.native="handleMouseEnter"
      @mouseleave.native="handleMouseLeave"
      >上传</el-button
    >
    <ul class="uploadTip" v-if="showUploadTip">
      <li>①清空表格数据后通过键盘上Ctrl C+V从本地excel粘贴数据</li>
      <li>② 建议优先通过表格复制粘贴方式上传，文件上传容易因为未知隐藏格式报错</li>
    </ul>
  </div>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import { Loading, Message } from "element-ui";
import { ContextMenu } from "handsontable/plugins/contextMenu";
import bus from "../utils/bus";
import {
  registerLanguageDictionary,
  // getLanguagesDictionaries,
  // deCH,
  // deDE,
  // esMX,
  // frFR,
  // itIT,
  // jaJP,
  // koKR,
  // lvLV,
  // nbNO,
  // nlNL,
  // plPL,
  // ptBR,
  // ruRU,
  zhCN,
  // zhTW,
} from "handsontable/i18n";
// registerLanguageDictionary(deCH);
// registerLanguageDictionary(deDE);
// registerLanguageDictionary(esMX);
// registerLanguageDictionary(frFR);
// registerLanguageDictionary(itIT);
// registerLanguageDictionary(jaJP);
// registerLanguageDictionary(koKR);
// registerLanguageDictionary(lvLV);
// registerLanguageDictionary(nbNO);
// registerLanguageDictionary(nlNL);
// registerLanguageDictionary(plPL);
// registerLanguageDictionary(ptBR);
// registerLanguageDictionary(ruRU);
registerLanguageDictionary(zhCN);
// registerLanguageDictionary(zhTW);
registerAllModules();

export default {
  props: {
    interface: {
      type: String,
    },
    xmldata: {
      type: Array,
    },
    params: {
      type: Object,
      default: () => ({
        projectname: "",
        file: "",
      }),
    },
    isTable:{
      type: Boolean,
      default: true
    }
  },
  components: {
    HotTable,
  },
  data() {
    return {
      // 鼠标移入上传按钮的时候看是否显示提示词
      showUploadTip: false,
      // 检查是否是vip
      VIP_Num: null,
      hotSettings: {
        // data: Array.from({ length: 15000 }, () => Array(26).fill()),

        rowHeaders: true,
        colHeaders: true,
        width: "100%",
        colWidths: 100,
        rowHeights: 25,
        manualColumnResize: true,
        contextMenu: true,
        copyPaste: true, // 启用复制和粘贴功能
        manualRowMove: true,
        manualColumnMove: true,
        manualRowResize: true,
        manualColumnResize: true,
        licenseKey: "non-commercial-and-evaluation",
        viewportRowRenderingOffset: 20, // 设置虚拟滚动的渲染偏移量
      },
      data: new Array(15000).fill().map(() => new Array(26).fill("")),
      language: "zh-CN", // 使用中文
      activeName: "first",
      theobject: null,
      data1: [],
      loadinginstace: null,
    };
  },
  created() {
    bus.$on("Send_Vip_Data", (data) => {
      // console.log(data);
      this.VIP_Num = data.user.vip;
      // console.log(this.VIP_Num);
    });
  },
  mounted() {
    // 初始化拖拽功能
    document.onmouseup = this.doUp;
    document.onmousemove = this.doMove;
    this.dataHandle();
    const hotInstance = this.$refs.hotTable.hotInstance;
    hotInstance.updateSettings({ language: "zh-CN" });
    // const doMoveDebounced = debounce(this.doMove, 16); // 控制频率为60fps

    // document.onmousemove = (event) => {
    //   doMoveDebounced(event);
    // };

    // 获取 Handsontable 实例
    // const hotInstance = this.$refs.hotTable.hotInstance;
    // 添加 afterChange 事件监听器
    hotInstance.addHook("afterChange", (changes, source) => {
      if (source === "paste") {
        // 处理粘贴完成后的操作
        console.log("用户粘贴完成");
      }
    });
  },
  methods: {
    // 看是否显示上传时的提示词
    handleMouseEnter() {
      this.showUploadTip = true;
    },
    handleMouseLeave() {
      this.showUploadTip = false;
    },
    // 上传示例文件
    uploadSampleFile() {
       const vipNum = sessionStorage.getItem('vipNum')
       console.log(vipNum,8888);
       this.VIP_Num = vipNum
      if (this.VIP_Num == 1 || this.VIP_Num == 2) {
        const trimString = (str) => {
          if (typeof str === "string") {
            return str.trim();
          }
          return str;
        };

        const isNullArray = (arr) => arr.every((item) => item === null);
        // const isDuplicateArray = (arr) => new Set(arr).size === 1;
        let arr = this.data1[0].filter((cell) => cell !== "" && cell !== null)
        if(this.data1[0][0] === "" || this.data1[0][0] === null){
          arr.unshift('')
        }
        const jsonData = []
        for (let i = 0; i < this.data1.length; i++) {
          let temp = []
          for (let j = 0; j < arr.length; j++) {
            temp.push(this.data1[i][j])
          }
          jsonData.push(temp)
        }
        for (let i = 0; i < jsonData.length; i++) {
          if (jsonData[jsonData.length-1].some((element) => element !== null || element !== undefined)) {
          }else{
            jsonData.pop()
          }
        }
        let jsonData1 = jsonData
          .map((row) =>
            row.map((cell) => trimString(cell))
          )
          .filter(
            (row) => {
              for (let i = 0; i < arr.length; i++) {
                if(row[row.length-1] === null || row[row.length-1] === '' || row[row.length-1] === undefined){
                  row.pop()
                }
              }
             return row.some((cell) => cell !== "") && !isNullArray(row)
            }
          );

        let csvContent = "";
        jsonData1.forEach((row) => {
          //上面已经把空行空列清除 这里不要在清除空字符
          // row = row.filter((item) => item !== "");
          csvContent += row.join(",") + "\r\n";
        });
        const blob = new Blob([csvContent], { type: "text/csv" });
        console.log(csvContent);
        this.params.file = new File([blob], "data.csv");
        // console.log(this.params.file);
        //启动 loading 加载
        this.dialogVisible = false;
        let t;
        let xhr = null;
        let time = 60;
        this.loadinginstace = Loading.service({
          lock: true,
          text: "正在处理，请稍后。。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        t = setInterval(() => {
          time--;
          if (time > 30) {
            switch (true) {
              case time <= 50 && 40 < time:
                this.loadinginstace.text =
                  "您提交的数据量较大，请耐心等待。。。";
                break;
              default:
                break;
            }
          } else {
            clearInterval(t);
            // if (xhr) {
            //     xhr.abort();
            // }
            this.loadinginstace.close();

            this.$confirm("正在处理，可在我的项目里获取结果", "提示", {
              confirmButtonText: "前往我的项目",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                // setTimeout(() => {
                // 设置要跳转的目标路由路径
                this.$router.push("ProjectOverview"); // 替换为你的目标路由路径
                // }, 2000);
              })
              .catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });
              });
          }
        }, 1000);
        this.$post("/upload/" + this.$route.query.pid, this.$props.params)
          .then((response) => {
              // 处理上传成功后的操作
            clearInterval(t);
            this.loadinginstace.close();
            // console.log("上传成功", response);
            if (response.data === '') {
                this.$message({
                  showClose: false,
                  message: '数据错误,请检查数据格式是否正确',
                  type: "error",
                });
              
            }else{
              this.modalOpen = false;
              if (
                response.data.error == "User Logged in elsewhere" ||
                response.data.error == "User Not Login"
              ) {
                this.$message({
                  showClose: false,
                  message: response.data.error,
                  type: "error",
                });
                this.$router.push("/");
              } else {
                if (response.data.error.length > 0) {
                  this.$message({
                    showClose: false,
                    message: response.data.error,
                    type: "error",
                  });
                } else if (response.data.error.msg) {
                  this.$message({
                    showClose: false,
                    message: response.data.error.msg,
                    type: "error",
                  });
                } else {
                  this.listsData = response.data;
                  this.$emit("handleSuccess", this.listsData);
                }
              }
            }
          })
          .catch((error) => {
            clearInterval(t);
            if (xhr) {
              xhr.abort(); // Abort the request if it's still ongoing
            }
            this.loadinginstace.close();
          });
      } else {
        this.$message({
          showClose: true,
          message: "仅限会员使用",
          type: "warning",
        });
        // 使用 setTimeout 设置延时
        setTimeout(() => {
          // 设置要跳转的目标路由路径
          this.$router.push("/PersonalCenter"); // 替换为你的目标路由路径
        }, 2000); // 3000毫秒（即3秒）的延时
      }
    },
    dataHandle() {
      this.data1.push(...this.$props.xmldata);
      if (this.data1.length < 15000) {
        for (let i = 0; i < 15000; i++) {
          this.data1.push(Array(this.data1[0].length));
        }
      }
      if (this.data1[0].length < 100) {
        for (let i = 0; i < 100; i++) {
          this.data1.forEach((item) => {
            item.push("");
          });
        }
      }
    },
    clear() {
      this.data1.forEach((items, index) => {
        items.forEach((item, i) => {
          if (item != "" || item == 0) {
            this.$set(items, i, "");
          }
        });
      });
    },

    handleClick(tab, event) {
      // console.log(tab, event);
    },
    choseExample() {
      // 跳转地址
      // this.$route.push({});
    },

    // 拖拽效果
    getReal(el, type, value) {
      let temp = el;
      while (temp !== null && temp.tagName !== "BODY") {
        if (eval(`temp.${type}`) === value) {
          el = temp;
          return el;
        }
        temp = temp.parentElement;
      }
      return el;
    },
    doDown(event) {
      const el = this.getReal(event.target, "className", "resizeMe");
      if (el === null) return;

      const dir = this.getDirection(el);
      if (dir === "") return;

      this.theobject = {
        el,
        dir,
        grabx: event.clientX,
        graby: event.clientY,
        width: el.offsetWidth,
        height: el.offsetHeight,
        left: el.offsetLeft,
        top: el.offsetTop,
      };

      event.returnValue = false;
      event.cancelBubble = true;
    },
    doUp() {
      if (this.theobject !== null) {
        this.theobject = null;
      }
    },
    doMove(event) {
      if (this.theobject !== null) {
        const { dir, el } = this.theobject;
        const { clientX, clientY } = event;
        const xMin = 420; // The smallest width possible
        const xMax = document.documentElement.clientWidth - 250; // The largest width possible

        if (dir.indexOf("w") !== -1) {
          el.style.left = `${Math.max(
            Math.min(
              this.theobject.left + clientX - this.theobject.grabx,
              this.theobject.left + this.theobject.width - xMin
            ),
            this.theobject.left - xMax + this.theobject.width
          )}px`;
          el.style.width = `${Math.max(
            xMin,
            Math.min(
              this.theobject.width - clientX + this.theobject.grabx,
              xMax
            )
          )}px`;
        }

        event.returnValue = false;
        event.cancelBubble = true;
      }

      // const resizeMeEl = this.getReal(event.target, "className", "resizeMe");
      // if (resizeMeEl !== null) {
      //   const dir = this.getDirection(resizeMeEl);
      //   if (dir === "") {
      //     resizeMeEl.style.cursor = "default";
      //   } else {
      //     resizeMeEl.style.cursor = `${dir}-resize`;
      //   }
      // }
    },
    getDirection(el) {
      const xPos = event.offsetX;
      const yPos = event.offsetY;
      const offset = 8; // The distance from the edge in pixels
      let dir = "";
      if (xPos < offset) dir += "w";
      return dir;
    }, //
    // 放大表格
    incre(num) {
      let wid = document.querySelectorAll(".resizeMe");
      wid[num].style.width = document.documentElement.clientWidth - 250 + "px";
    },
    desc(num) {
      let wid = document.querySelectorAll(".resizeMe");
      wid[num].style.width = 420 + "px";
    },
    //
    // handleContextMenu(key, selection, clickEvent) {
    //   const hotInstance = this.$refs.hotTable.hotInstance;
    //   const selectedRow = selection[0].start.row;
    //   const selectedCol = selection[0].start.col;

    //   switch (key) {
    //     case "row_above":
    //       hotInstance.alter("insert_row", selectedRow);
    //       break;
    //     case "row_below":
    //       hotInstance.alter("insert_row", selectedRow + 1);
    //       break;
    //     case "col_left":
    //       hotInstance.alter("insert_col", selectedCol);
    //       break;
    //     case "col_right":
    //       hotInstance.alter("insert_col", selectedCol + 1);
    //       break;
    //     case "remove_row":
    //       hotInstance.alter("remove_row", selectedRow);
    //       break;
    //     case "remove_col":
    //       hotInstance.alter("remove_col", selectedCol);
    //       break;
    //     default:
    //       break;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tab-pane {
  height: 100%;

  #Copy {
    height: 100%;
  }
}

.copyAll {
  height: calc(100% - 70px);

  /* // display: flex; */
  .copy {
    height: 100%;
    overflow: initial;

    .all {
      height: 100%;

      #testDiv {
        width: 420px;
        height: 100%;
        margin: 0px 0px 0px auto;

        .line {
          height: 99%;
          top: 6.5px;
          width: 14px;
        }
      }
    }

    .tips {
      position: relative;
      top: -48px;
      left: 84px;
      float: left;
      /* // width: 211px; */
      width: 26px;
      display: flex;
      align-items: center;

      .pic {
        height: 26px;
        width: 26px;
      }

      .tit {
        font-size: 12px;
        display: none;
        margin-left: 6px;
        width: 183px;
        position: absolute;
        left: 30px;
        color: red;
      }
    }

    .tips:hover .tit {
      display: block;
    }

    .incre {
      position: relative;
      top: -45px;
      float: right;
      font-size: 20px;
    }

    .desc {
      position: absolute;
      top: -43px;
      right: 80px;
      font-size: 13px;
    }
  }

  button {
    margin-top: 10px;
    cursor: pointer;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

::v-deep .innerTab {
  position: absolute;
  top: 0;
  left: 20px;
  right: 0;
  height: 100%;
  overflow: auto;

  .htRowHeaders {
    height: 100%;

    .ht_master {
      height: 100%;

      .wtHolder {
        height: 100%;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .ht_master {
    .wtHider {
      width: 100% !important;

      .wtSpreader {
        width: 100%;

        th {
          width: 100px !important;
        }

        td {
          width: 100px !important;
        }

        > .htCore {
          width: 100%;
        }
      }
    }

    .ht_clone_top {
      width: 100% !important;

      .wtHider {
        width: 100% !important;
      }
    }
  }

  .hot-display-license-info {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
}
.uploadTip {
  // display: block;
  position: absolute;
  bottom: 6px;
  width: 276px;
  left: 87px;
  font-size: 12px;
  color: #fd5151;
  list-style: none;
}
// ::v-deep .el-button--success:hover .uploadTip {
//   // display: block ;
//   color: black !important;
// }
/* // ::v-deep .handsontable {
  //   tr{
  //     height: 30px;
  //   }
  //   .relative {
  //     // line-height: 4;
  //     height: calc(100% + 1px);

  //     .rowHeader {
  //       height: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //   }
  // } */

.innerTab .ht_master .wtHolder .innerTab::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 10px;
  /* 对应横向滚动条的宽度 */
  height: 10px;
}

/* 滚动条上的滚动滑块 */
.ht_master .wtHolder .innerTab::-webkit-scrollbar-thumb {
  background-color: #49b1f5;
  border-radius: 32px;
}

/* 滚动条轨道 */
.ht_master .wtHolder .innerTab::-webkit-scrollbar-track {
  background-color: #dbeffd;
  border-radius: 32px;
}
</style>
<style>
.htMenu {
  height: 300px !important;
  /* left: 1200px !important; */
  width: 220px !important;
  right: 100px !important;
}
.el-tabs--border-card > .el-tabs__header {
  background-color: #765fb1;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #fff;
}
.el-collapse-item__header {
  background: #8fb8d5;
  border-radius: 6px;
  color: #fff;
  padding: 0 10px;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: #8fb8d5;
  color: #fff;
}
</style>