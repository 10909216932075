<template>
  <div class="total" style="position: relative; display: inline-block">
    <el-dropdown trigger="click">
      <el-tooltip placement="right" effect="dark">
        <div slot="content" style="font-size: 14px; width: 300px">
          ①出图后双击图片会出现虚线外框，可通过拉伸虚线外框调节图表长宽比例；同时可通过鼠标滑轮调节图片大小；点击图例可拖动图例位置。<br />②导出矢量PDF后可通过Adobe
          Acrobat Pro D（PDF编辑器）软件自定义编辑全图的标题、坐标轴、
          图例和标签的字体大小、位置、添加/删除横线等细节的精细调整。
        </div>
        <el-button type="primary" trigger="click" class="dow">
          下载图片<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
      </el-tooltip>
      <el-dropdown-menu class="download-buttons">
        <el-dropdown-item>
          <el-button class="item" type="primary" @click="downloadChart('png')">
            Download PNG
          </el-button>
        </el-dropdown-item>
        <el-dropdown-item @click="downloadChart('tiff')">
          <el-button
            class="item"
            type="primary"
            @click="downloadChart('tiff')"
            style="margin: 10px 0 0 0"
            >Download TIFF</el-button
          >
        </el-dropdown-item>
        <el-dropdown-item @click="downloadChart('pdf')">
          <el-button
            class="item"
            type="primary"
            @click="downloadChart('pdf')"
            style="margin: 10px 0"
            >Download PDF</el-button
          >
        </el-dropdown-item>
        <el-dropdown-item @click="downloadChart('svg')">
          <el-button class="item" type="primary" @click="downloadChart('svg')"
            >Download SVG</el-button
          >
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <div class="tip2" style="">
      目前PNG、TIFF、SVG格式下载的图片DPI＜300，PDF的DPI大于500，如果用于SCI文章发表需选择PDF格式，导出矢量PDF后可通过WPS或PS/AI软件任意编辑全图的标题、坐标轴、图例和标签的字体大小、位置、添加/删除横线等细节调整。
    </div> -->
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "svg2pdf.js";
import svgformat from "../../assets/svg/svgformat";
export default {
  props: {
    doms: {
      type: HTMLDivElement,
    },
    isTrue: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Array,
      // default: [1000,800]
    },
  },
  mounted() {},
  methods: {
    getPDf() {
      const ele = this.$props.doms;
      let element = ele.querySelector("#chart div").innerHTML;
      console.log(element);
      // element = JSON.stringify(element);
      // 将参数值设置到隐藏的input元素中
      document.getElementById("paramInput").value = element;

      // 提交表单
      document.getElementById("pdfForm").submit();
    },
    // 下载图片
    downloadChart(format) {
      // console.log(this.$props.doms)
      const ele = this.$props.doms;
      const element = ele.querySelector("svg");
      if (format === "png") {
        html2canvas(ele, { scale: 3 }).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, "chart.png");
          });
        });
      } else if (format === "tiff") {
        html2canvas(ele, { scale: 5 }).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, "chart.tiff");
          });
        });
      } else if (format === "pdf") {
        // doc.setFont("微软雅黑"); // 设置使用微软雅黑字体
        // doc.addFont("fonts/bold.ttf", "msyh", "bold");
        this.getPDf();
      } else if (format === "svg") {
        const blob = new Blob([element.outerHTML], {
          type: "image/svg+xml",
        });
        saveAs(blob, "chart.svg");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.total:hover .tip2 {
  display: block !important;
}
.tip2 {
  display: none;
  position: absolute;
  left: 163px;
  bottom: 0;
  width: 300px;
  font-size: 14px;
  color: #999;
  text-align: justify;
}
</style>