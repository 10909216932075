<template>
  <img :src="chartImg" alt="" style=" height: 95%" />
</template>

<script>
export default {
  data() {
    return {
      chartImg: null,
    };
  },
  created() {
    this.getChartData();
  },
  methods: {
    async getChartData() {
      let formData = new FormData();
      formData.append("pid", this.$route.query.pid);
      const location_data = await this.$api.getDetailData(formData);
      // console.log(location_data[0],999);
      this.chartImg = location_data[0].cover.slice(5);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>