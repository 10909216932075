<template>
    <div :class="isBlod ? 'Blod-active' : 'Blod'" @click="BlodFun"
        style="width: 23px;height: 30px; cursor: pointer; text-align: center;line-height: 30px;">B</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean
        }
    },
    data() {
        return {
            isBlod: this.value,
        }
    },
    methods: {
        BlodFun() {
            this.isBlod = !this.isBlod
            this.$emit('input', this.isBlod); // 通过 input 事件更新父组件的数据
            this.$emit('drawChart'); // 触发自定义事件
        },
    }
}
</script>

<style scoped>
div {
    position: absolute;
    right: 0px;
    border: 1px solid #d8d8d8;
}

.Blod-active {
    color: #409EFF;
    border: 1px solid #409EFF;
}

.Blod {
    border: 1px solid #d8d8d8;
    color: #333;
}
</style>