import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const biologicalInformationAnalysis = [
    {
        path: "Tumorbioinformatics",
        name: "Tumorbioinformatics",
        component: () => import("@/views/biologicalInformationAnalysis/Tumorbioinformatics/Tumorbioinformatics.vue"),
        
    },  
    {
        path: "geneMutationWaterfull",
        name: "geneMutationWaterfull",
        component: () => import("@/views/AdvancedAnalysis/mutationAnalysis/geneMutationWaterfull"),
    }, 
    {
        // 基因突变热图
        path: "GeneMutationHeatMap",
        name: "GeneMutationHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/GeneMutationHeatMap"),
        meta: {

        }
    },
]
const WithTypes = biologicalInformationAnalysis.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "肿瘤生信",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "肿瘤生信", // 替换成你想要的类型值
        },
    };
});

export default WithTypes