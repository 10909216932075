<template>
  <div class="total" style="position: relative; display: inline-block">
    <el-dropdown trigger="click">
      <el-tooltip placement="right" effect="dark">
        <div slot="content" style="font-size: 14px; width: 300px">
         ①出图后双击图片会出现虚线外框，可通过拉伸虚线外框调节图表长宽比例；同时可通过鼠标滑轮调节图片大小；点击图例可拖动图例位置。<br />②导出矢量PDF后可通过Adobe Acrobat Pro D（PDF编辑器）软件自定义编辑全图的标题、坐标轴、 图例和标签的字体大小、位置、添加/删除横线等细节的精细调整。
        </div>
        <el-button type="primary" trigger="click" class="dow">
          下载图片<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
      </el-tooltip>
      <el-dropdown-menu class="download-buttons">
        <div class="top" style="display: flex; align-items: center">
          <span @mouseover="showDiv2" @mouseout="hideDiv2">
            <el-dropdown-item>
              <el-button
                class="item"
                type="primary"
                @click="downloadChart('png')"
              >
                Download PNG
              </el-button>
            </el-dropdown-item>
            <el-radio-group
              v-model="radio"
              v-show="showSecondDiv"
              style="
                position: absolute;
                top: 0;
                left: 177px;
                padding: 10px;
                background: #fff;
                border-radius: 10px;
              "
            >
              <el-radio :label="2" style="padding: 5px 0">300 dpi</el-radio>
              <el-radio :label="3" style="padding: 5px 0">600 dpi</el-radio>
              <el-radio :label="5" style="padding: 5px 0">900 dpi</el-radio>
            </el-radio-group>
          </span>
        </div>
        <div
          v-if="!is3D"
          class="top"
          style="display: flex; align-items: center"
        >
          <span @mouseover="showDiv3" @mouseout="hideDiv3">
            <el-dropdown-item @click="downloadChart('tiff')">
              <form
                id="pdfForm1"
                action="https://cnsknowall.com/api/image2tiff"
                method="post"
              >
                <input type="hidden" id="paramInput1" name="data" value="" />
                <input type="hidden" id="paramInput12" name="dpi" value="" />
                <el-button
                  class="item"
                  type="primary"
                  style="margin: 10px 0"
                  @click="downloadChart('tiff')"
                  >Download TIFF</el-button
                >
              </form>
            </el-dropdown-item>
            <el-radio-group
              v-model="radio1"
              v-show="showSecondDiv1"
              style="
                position: absolute;
                top: 35px;
                left: 177px;
                padding: 10px;
                background: #fff;
                border-radius: 10px;
              "
            >
              <el-radio :label="300" style="padding: 5px 0">300 dpi</el-radio>
              <el-radio :label="600" style="padding: 5px 0">600 dpi</el-radio>
              <el-radio :label="900" style="padding: 5px 0">900 dpi</el-radio>
            </el-radio-group>
          </span>
        </div>
        <div
          v-if="!is3D"
          class="top"
          style="display: flex; align-items: center"
        >
          <el-dropdown-item @click="downloadChart('pdf')">
            <form
              id="pdfForm"
              action="https://cnsknowall.com/api/html2pdf"
              method="post"
            >
              <!-- 添加一个隐藏的input元素用于存放参数 -->
              <input type="hidden" id="paramInput" name="data" value="" />

              <!-- 其他的表单元素可以放在这里，如果需要的话 -->

              <!-- 提交按钮 -->
              <!-- <input type="button" value="生成PDF" @click="downloadChart('pdf')" /> -->
              <el-button
                class="item"
                type="primary"
                @click="downloadChart('pdf')"
                style="margin-bottom: 10px"
                >Download PDF</el-button
              >
            </form>
            <!-- <el-button
          class="item"
          type="primary"
          @click="downloadChart('pdf')"
          style="margin: 10px 0"
          >Download PDF</el-button
        > -->
          </el-dropdown-item>
          <!-- <el-radio v-model="radio2" label="300">300 dpi</el-radio>
          <el-radio v-model="radio2" label="600">600 dpi</el-radio>
          <el-radio v-model="radio2" label="900" style="margin-right: 20px"
            >900 dpi</el-radio
          > -->
        </div>
        <div
          v-if="!is3D"
          class="top"
          style="display: flex; align-items: center"
        >
          <el-dropdown-item @click="downloadChart('svg')">
            <el-button class="item" type="primary" @click="downloadChart('svg')"
              >Download SVG</el-button
            >
          </el-dropdown-item>
          <!-- <el-radio v-model="radio3" label="300">300 dpi</el-radio>
          <el-radio v-model="radio3" label="600">600 dpi</el-radio>
          <el-radio v-model="radio3" label="900" style="margin-right: 20px"
            >900 dpi</el-radio
          > -->
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <div class="tip2" style="">
      目前PNG、TIFF、SVG格式下载的图片DPI＜300，PDF的DPI大于500，如果用于SCI文章发表需选择PDF格式，导出矢量PDF后可通过WPS或PS/AI软件任意编辑全图的标题、坐标轴、图例和标签的字体大小、位置、添加/删除横线等细节调整。
    </div> -->
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "svg2pdf.js";
import svgformat from "../../assets/svg/svgformat";
import { Loading, Message } from "element-ui"; //项目中引用
import changedpi from "@/utils/png.js";
export default {
  props: {
    doms: {
      type: HTMLDivElement,
    },
    isTrue: {
      type: Boolean,
      default: false,
    },
    is3D: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSecondDiv1: false,
      showSecondDiv: false,
      radio: 3,
      radio1: 600,
      radio2: 600,
      radio3: 600,
      loadinginstace: null,
    };
  },
  mounted() {},
  methods: {
    showDiv2() {
      this.showSecondDiv = true;

      // console.log(666, this.showSecondDiv);
    },
    hideDiv2() {
      this.showSecondDiv = false;
    },
    showDiv3() {
      this.showSecondDiv1 = true;

      // console.log(666, this.showSecondDiv1);
    },
    hideDiv3() {
      this.showSecondDiv1 = false;
    },
    //解决加载字体卡顿问题
    _runTask(task, callback) {
      requestIdleCallback((idle) => {
        // console.log(idle.timeRemaining())
        if (idle.timeRemaining() > 0) {
          // const font1 = document.createElement("link");
          // font1.href = "fonts/bold.ttf";
          // font1.rel = "stylesheet";
          // document.head.appendChild(font1);
          // const font2 = document.createElement("link");
          // // font2.href = "fonts/normal.ttf";
          // font2.rel = "stylesheet";
          // document.head.appendChild(font2);
          const ele = this.$props.doms;
          const element = ele.querySelector("svg");

          console.log(element);
          svgformat();
          const svgWidth = element.getAttribute("width");
          const svgHeight = element.getAttribute("height");
          let doc = null;
          if (svgHeight <= svgWidth || svgWidth >= 1000) {
            doc = new jsPDF("landscape", "pt", [
              parseFloat(svgWidth),
              parseFloat(svgHeight),
            ]);
          } else if (svgHeight > svgWidth) {
            doc = new jsPDF("landscape", "pt", [
              parseFloat(svgHeight),
              parseFloat(svgHeight),
            ]);
          }

          // doc.addFont("fonts/normal.ttf", "msyh", "normal");
          doc.addFont("fonts/bold.ttf", "msyh", "bold");

          doc.svg(element).then(() => {
            this.loadinginstace.close();
            doc.save("chart.pdf");
          });
        }
      });
    },
    runTask(task) {
      return new Promise((resolve) => {
        this.loadinginstace = Loading.service({
          lock: true,
          text: "正在疯狂的下载中，请稍后。。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        this._runTask(task, resolve);
      });
    },
    getPDf() {
      const ele = this.$props.doms;
      console.log(ele, ele.id);
      // 动态获取id下面的div和svg元素
      let element = ele.querySelector(`#${ele.id} svg`).parentNode.innerHTML;
      console.log(element);
      // element = JSON.stringify(element);
      // 将参数值设置到隐藏的input元素中
      document.getElementById("paramInput").value = element;

      // 提交表单
      document.getElementById("pdfForm").submit();
    },
    getTiff() {
      const ele = this.$props.doms;
      console.log(ele, ele.id);
      // 动态获取id下面的div和svg元素
      // let element = ele.querySelector(`#${ele.id} svg`).parentNode.innerHTML;
      let element1 = ele.querySelector(`svg`);
      let element = element1.querySelectorAll("path");
      console.log(element1, element1.width, element);
      element.forEach(function (path) {
        // 检查 path 元素的 fill 属性是否为 "transparent"，如果是，则添加 fill-opacity 属性
        if (path.getAttribute("fill") === "transparent") {
          path.setAttribute("fill-opacity", "0");
        }
      });

      let element2 = element1.parentNode.innerHTML;
      console.log(element, element1, element2);
      // element = JSON.stringify(element);
      // 将参数值设置到隐藏的input元素中
      // if (element2.includes("\x3C!---->")) {
      //   element2.replace("/\x3C!---->/g", ""); // 删除所有字符串
      // }
      console.log(element2);
      if (element2.includes("<!---->")) {
        // console.log('需要进行删除',console.log(element2 ));
        let arr = element2.split('<!---->');
        element2 = arr.join(''); // 删除空格
        console.log(element2 );
      }
      
      document.getElementById("paramInput1").value = element2;
      document.getElementById("paramInput12").value = this.radio1;
      // 提交表单
      document.getElementById("pdfForm1").submit();
    },
    // async getPDf() {
    //   const ele = this.$props.doms;
    //   let element = ele.querySelector("#chart div").innerHTML;
    //   console.log(JSON.stringify(element));
    //   element = JSON.stringify(element)
    //   console.log(element);
    //   window.open(
    //     "https://cnsknowall.com/api/html2pdf?data=" + element
    //   );
    //   // const data = await this.$api.GetPDFdata(formData);
    //   // console.log(data);
    //   // this.data = data.data.res.data;s
    // },
    // 下载图片
    downloadChart(format) {
      if (this.$props.isTrue) {
        const ele = this.$props.doms;
        
        const element = ele.querySelector("svg");
        console.log(ele,element);
        if (format === "png") {
          html2canvas(ele, { scale: this.radio, dpi: 600 }).then((canvas) => {
            canvas.toBlob((blob) => {
              saveAs(blob, "chart.png");
            });
          });
        } else if (format === "tiff") {
          this.getTiff();
        } else if (format === "pdf") {
          this.getPDf();
          // doc.setFont("微软雅黑"); // 设置使用微软雅黑字体
          // doc.addFont("fonts/bold.ttf", "msyh", "bold");
          // this.runTask();
        } else if (format === "svg") {
          const blob = new Blob([element.outerHTML], {
            type: "image/svg+xml",
          });
          saveAs(blob, "chart.svg");
        }
      } else {
        this.$message({
          showClose: true,
          message: "请上传数据后进行下载",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.total:hover .tip2 {
  display: block !important;
}

.tip2 {
  display: none;
  position: absolute;
  left: 163px;
  bottom: 0;
  width: 300px;
  font-size: 14px;
  color: #999;
  text-align: justify;
}
</style>