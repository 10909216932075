<template>
    <el-button type="success" size="medium" @click="recover">参数保存</el-button>
</template>

<script>
export default {
    props: {
        isClick: {
            type: Boolean,
            default: true
        },
        parentRef: {
            type: Object,
            default: null
        },
        parentRef2: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            value: this.isClick,
        };
    },
    methods: {
        recover() {
            if (sessionStorage.getItem('Symble') == 1) {
                this.parentRef.toUpload();
            } else if (sessionStorage.getItem('Symble') == 2) {
                this.parentRef2.uploadSampleFile();
            }
            // this.parentRef.uploadSampleFile()
            this.$nextTick(() => {
                setTimeout(() => {
                    this.value = true
                    this.$emit("input", this.value);
                }, 200);

            })
        },
    },
}
</script>

<style scoped>
/* 样式 */
</style>