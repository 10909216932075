<template>
    <div style="margin: 20px 0; display: flex; align-items: center;justify-content: space-between; position: relative;">
        <label class="labelFontSize item" for="labelFontSize">标签角度</label>
        <el-select v-model="angle" placeholder="请选择" @change="angleFun">
            <el-option v-for="item in angles" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            angle: this.value,
            angles: [
                {
                    value: 0,
                    label: 0,
                },
                {
                    value: 30,
                    label: 30,
                },
                {
                    value: 45,
                    label: 45,
                },
                {
                    value: 60,
                    label: 60,
                },
                {
                    value: 90,
                    label: 90,
                },
            ],
        }
    },
    methods: {
        angleFun() {
            this.$emit('input', this.angle); // 通过 input 事件更新父组件的数据
            this.$emit('drawChart'); // 触发自定义事件
        },
    }
}
</script>

<style></style>