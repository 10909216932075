<template>
  <el-collapse-item title="X轴设置" name="3">
    <!-- -----x轴标题------------------------------------------------------------------------------------------ -->
    <div class="xAxisTitle">x轴标题样式</div>
    <FontFamily v-model="data.nameTextStyle.fontFamily" :label="'字体选择'"/>
    <div class="set_set_set">
      <label class="labelFontSize item" for="labelFontSize">标题字号:</label>
      <div class="set_set_set_set">
        <Range style="margin:0 0 0" v-model="data.nameTextStyle.fontSize" :min="0" :max="50" :step="1"/>
        <div :class="data.nameTextStyle.fontWeight === 'bold' ? 'Blod-active' : 'Blod'" @click="nameFontWeight" >B</div>
        <div :class="data.nameTextStyle.fontStyle === 'italic' ? 'Blod-active' : 'Blod'" @click="nameFontStyle" >I</div>
      </div>
    </div>
    <div class="set_set_set">
      <label class="colorPicker item">标题颜色:</label>
      <colors v-model="data.nameTextStyle.color"/>
    </div>
    <div style="display:flex;justify-content:center;">
        <div style="color:rgba(0,0,0,0.4);">- - - - - - - - - - - - - - - -  - - - - - - - - - - </div>
    </div>
    <!-- -----x轴标签------------------------------------------------------------------------------------------ -->
    <div class="xAxisTitle">x轴标签样式</div>
    <FontFamily v-model="data.axisLabel.fontFamily" :label="'字体选择'"/>
    <div class="set_set_set">
      <label class="labelFontSize item" for="labelFontSize">标签字号:</label>
      <div class="set_set_set_set">
        <Range style="margin:0 0 0" v-model="data.axisLabel.fontSize" :min="0" :max="50" :step="1"/>
        <div :class="data.axisLabel.fontWeight === 'bold' ? 'Blod-active' : 'Blod'" @click="axisLabelFontWeight" >B</div>
        <div :class="data.axisLabel.fontStyle === 'italic' ? 'Blod-active' : 'Blod'" @click="axisLabelFontStyle" >I</div>
      </div>
    </div>
    <div class="set_set_set">
      <label class="colorPicker item">标签颜色:</label>
      <colors v-model="data.axisLabel.color"/>
    </div>
  </el-collapse-item>
</template>

<script>
export default {
  props: {
    xAxisData: {
      type: Object,
    },
  },
  data() {
    return {
      data:this.xAxisData,
    }
  },
  created() {},
  computed:{
    modifiedProp:{
      get(){
        return this.xAxisData
      },
      set(newVal){
        this.$emit("update:xAxisData",newVal)
      }
    },
  },
  methods: {
    nameFontWeight() {
      if (this.data.nameTextStyle.fontWeight === 'bold') {
        this.data.nameTextStyle.fontWeight = 'normal'
      }else{
        this.data.nameTextStyle.fontWeight = 'bold'
      }
      this.modifiedProp = this.data;//使用计算属性更新Prop的值
    },
    nameFontStyle() {
      if (this.data.nameTextStyle.fontStyle === 'italic') {
        this.data.nameTextStyle.fontStyle = 'normal'
      }else{
        this.data.nameTextStyle.fontStyle = 'italic'
      }
      this.modifiedProp = this.data;//使用计算属性更新Prop的值
    },
    axisLabelFontWeight() {
      if (this.data.axisLabel.fontWeight === 'bold') {
        this.data.axisLabel.fontWeight = 'normal'
      }else{
        this.data.axisLabel.fontWeight = 'bold'
      }
      this.modifiedProp = this.data;//使用计算属性更新Prop的值
    },
    axisLabelFontStyle() {
      if (this.data.axisLabel.fontStyle === 'italic') {
        this.data.axisLabel.fontStyle = 'normal'
      }else{
        this.data.axisLabel.fontStyle = 'italic'
      }
      this.modifiedProp = this.data;//使用计算属性更新Prop的值
    },
  },
  watch: {
    setGraph: {
      handler: function () {
        // this.getData()
        // this.getOption()
        // this.myEcharts()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .xAxisTitle{
    color: rgba(0, 0, 0, 0.8);
    font-weight:700;
    font-size: 15px;
  }
  .set_set_set{
    display:flex;
    align-items:center;
    justify-content:space-between;
    position:relative;
    margin:0 0 20px 0;
  }
  .set_set_set_set{
    width: 217px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    position:relative;
  }
  .Blod-active {
    color: #409EFF;
    border: 1px solid #409EFF;
    width: 23px;height: 30px; cursor: pointer; text-align: center;line-height: 30px;
  }
  .Blod {
    border: 1px solid #d8d8d8;
    color: #333;
    width: 23px;height: 30px; cursor: pointer; text-align: center;line-height: 30px;
  }
</style>
